import { Appear, Notes, Split, Image } from "mdx-deck";
import { CodeSurfer, Step, CodeSurferColumns } from "code-surfer";
import { github, nightOwl } from "@code-surfer/themes";
import React from 'react';
export default {
  Appear: Appear,
  Notes: Notes,
  Split: Split,
  Image: Image,
  CodeSurfer: CodeSurfer,
  Step: Step,
  CodeSurferColumns: CodeSurferColumns,
  github: github,
  nightOwl: nightOwl,
  React: React
};