'use strict';

require("core-js/modules/es7.string.trim-left");

var define = require('define-properties');

var getPolyfill = require('./polyfill');

module.exports = function shimTrimLeft() {
  var polyfill = getPolyfill();
  define(String.prototype, {
    trimLeft: polyfill
  }, {
    trimLeft: function trimLeft() {
      return String.prototype.trimLeft !== polyfill;
    }
  });
  return polyfill;
};