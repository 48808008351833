'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var has = require('has');

var $TypeError = GetIntrinsic('%TypeError%');

module.exports = function IsPropertyDescriptor(ES, Desc) {
  if (ES.Type(Desc) !== 'Object') {
    return false;
  }

  var allowed = {
    '[[Configurable]]': true,
    '[[Enumerable]]': true,
    '[[Get]]': true,
    '[[Set]]': true,
    '[[Value]]': true,
    '[[Writable]]': true
  };

  for (var key in Desc) {
    // eslint-disable-line
    if (has(Desc, key) && !allowed[key]) {
      return false;
    }
  }

  if (ES.IsDataDescriptor(Desc) && ES.IsAccessorDescriptor(Desc)) {
    throw new $TypeError('Property Descriptors may not be both accessor and data descriptors');
  }

  return true;
};