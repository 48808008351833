'use strict';

require("core-js/modules/es6.number.is-finite");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.is-nan");

var $isNaN = Number.isNaN || function (a) {
  return a !== a;
};

module.exports = Number.isFinite || function (x) {
  return typeof x === 'number' && !$isNaN(x) && x !== Infinity && x !== -Infinity;
};