'use strict';

require("core-js/modules/es6.function.name");

var getInferredName;

try {
  // eslint-disable-next-line no-new-func
  getInferredName = Function('s', 'return { [s]() {} }[s].name;');
} catch (e) {}

var inferred = function inferred() {};

module.exports = getInferredName && inferred.name === 'inferred' ? getInferredName : null;