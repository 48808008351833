'use strict';

var hasSymbols = require('has-symbols')();

var GetIntrinsic = require('../GetIntrinsic');

var callBound = require('./callBound');

var $iterator = GetIntrinsic('%Symbol.iterator%', true);
var $arraySlice = callBound('Array.prototype.slice');
var $arrayJoin = callBound('Array.prototype.join');

module.exports = function getIteratorMethod(ES, iterable) {
  var usingIterator;

  if (hasSymbols) {
    usingIterator = ES.GetMethod(iterable, $iterator);
  } else if (ES.IsArray(iterable)) {
    usingIterator = function usingIterator() {
      var i = -1;
      var arr = this; // eslint-disable-line no-invalid-this

      return {
        next: function next() {
          i += 1;
          return {
            done: i >= arr.length,
            value: arr[i]
          };
        }
      };
    };
  } else if (ES.Type(iterable) === 'String') {
    usingIterator = function usingIterator() {
      var i = 0;
      return {
        next: function next() {
          var nextIndex = ES.AdvanceStringIndex(iterable, i, true);
          var value = $arrayJoin($arraySlice(iterable, i, nextIndex), '');
          i = nextIndex;
          return {
            done: nextIndex > iterable.length,
            value: value
          };
        }
      };
    };
  }

  return usingIterator;
};