'use strict';

var ES = require('es-abstract/es2019');

module.exports = function flat() {
  var O = ES.ToObject(this);
  var sourceLen = ES.ToLength(ES.Get(O, 'length'));
  var depthNum = 1;

  if (arguments.length > 0 && typeof arguments[0] !== 'undefined') {
    depthNum = ES.ToInteger(arguments[0]);
  }

  var A = ES.ArraySpeciesCreate(O, 0);
  ES.FlattenIntoArray(A, O, sourceLen, 0, depthNum);
  return A;
};