'use strict';

var bind = require('function-bind');

var GetIntrinsic = require('../GetIntrinsic');

var $Function = GetIntrinsic('%Function%');
var $apply = $Function.apply;
var $call = $Function.call;

module.exports = function callBind() {
  return bind.apply($call, arguments);
};

module.exports.apply = function applyBind() {
  return bind.apply($apply, arguments);
};