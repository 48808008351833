'use strict';

require("core-js/modules/es7.string.trim-right");

var define = require('define-properties');

var getPolyfill = require('./polyfill');

module.exports = function shimTrimRight() {
  var polyfill = getPolyfill();
  define(String.prototype, {
    trimRight: polyfill
  }, {
    trimRight: function trimRight() {
      return String.prototype.trimRight !== polyfill;
    }
  });
  return polyfill;
};