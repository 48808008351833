'use strict';

var every = require('./every');

module.exports = function isSamePropertyDescriptor(ES, D1, D2) {
  var fields = ['[[Configurable]]', '[[Enumerable]]', '[[Get]]', '[[Set]]', '[[Value]]', '[[Writable]]'];
  return every(fields, function (field) {
    if (field in D1 !== field in D2) {
      return false;
    }

    return ES.SameValue(D1[field], D2[field]);
  });
};