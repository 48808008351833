'use strict';

var $strSlice = require('../helpers/callBound')('String.prototype.slice');

module.exports = function isPrefixOf(prefix, string) {
  if (prefix === string) {
    return true;
  }

  if (prefix.length > string.length) {
    return false;
  }

  return $strSlice(string, 0, prefix.length) === prefix;
};