'use strict';

var bind = require('function-bind');

var define = require('define-properties');

var implementation = require('./implementation');

var getPolyfill = require('./polyfill');

var shim = require('./shim');

var bound = bind.call(Function.call, getPolyfill());
define(bound, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
module.exports = bound;