'use strict';

require("core-js/modules/es7.string.trim-left");

var implementation = require('./implementation');

module.exports = function getPolyfill() {
  if (!String.prototype.trimLeft) {
    return implementation;
  }

  var zeroWidthSpace = "\u200B";

  if (zeroWidthSpace.trimLeft() !== zeroWidthSpace) {
    return implementation;
  }

  return String.prototype.trimLeft;
};