'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var has = require('has');

var $assign = GetIntrinsic('%Object%').assign;

module.exports = function assign(target, source) {
  if ($assign) {
    return $assign(target, source);
  } // eslint-disable-next-line no-restricted-syntax


  for (var key in source) {
    if (has(source, key)) {
      target[key] = source[key];
    }
  }

  return target;
};